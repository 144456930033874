import { useState, useEffect } from 'react';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { IModalEntity } from '../global-ui.types';

/**
 *
 * Modal Hook
 *  : Modal Context를 반환하는 Hook
 *
 */
export const useGlobalModal = () => {
  const router = useHlpRouter();

  // Modal Data
  const [modalInfo, setModalInfo] = useState<IModalEntity[]>([]);

  const openModal = (modal: Partial<IModalEntity>) => {
    setModalInfo((prev) => {
      // 추가될 모달 ID
      const { modalId: newModalId } = modal;

      // 추가될 모달 ID가 있고 기존에 ID가 존재할 경우 무시
      if (newModalId && prev.findIndex(({ modalId }) => modalId === newModalId) > -1) {
        return prev;
      }

      return [...prev, modal];
    });
  };

  const alert = (contents: React.ReactNode, title = '알림'): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalInfo((prev) => [
        ...prev,
        {
          type: 'dialog',
          title,
          bodyContents: <div className="typography-body2 text-body2">{contents}</div>,
          mainBtnText: '확인',
          onMainBtnClick() {
            closeModal();
            resolve(true);
          }
        }
      ]);
    });
  };

  const confirm = (contents: React.ReactNode, title = '알림'): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalInfo((prev) => [
        ...prev,
        {
          type: 'dialog',
          title,
          bodyContents: <div className="typography-body2 text-body2">{contents}</div>,
          subBtnText: '취소',
          mainBtnText: '확인',
          onMainBtnClick() {
            closeModal();
            resolve(true);
          },
          onSubBtnClick() {
            closeModal();
            resolve(false);
          }
        }
      ]);
    });
  };

  const closeModal = () => {
    setModalInfo((modal) => modal.filter((obj, index) => index !== modal.length - 1));
  };

  const closeAllModal = () => {
    setModalInfo([]);
  };

  // 페이지 이동시에 모달 전부 닫기
  useEffect(() => {
    router.events.on('routeChangeStart', closeAllModal);

    return () => {
      router.events.off('routeChangeStart', closeAllModal);
    };
  }, [router]);

  return {
    modalInfo,
    openModal,
    alert,
    confirm,
    closeModal,
    closeAllModal
  };
};
