import { CSSProperties } from 'react';
import styled from '@hanwhalife/shared-libs/emotion/styled';
import { css } from '@hanwhalife/shared-libs/emotion/react';
import { borderRadiusDesktop, maxWidthDesktopCenter, Spacing } from '@hanwhalife/styled/_shared/theme';

/**
 * 스크롤 시, 화면 상단에 고정시키기 위한 래퍼입니다.
 */
export const StyledStickyContainer = styled.div<{
  top?: number;
  left?: number;
  zindex?: number;
  bgColor?: CSSProperties['backgroundColor'];
  isGradient?: boolean;
}>`
  position: sticky;
  top: ${({ top }) => (top ? `${top}px` : '0')};
  left: ${({ left }) => (left ? `${left}px` : '0')};
  z-index: ${({ zindex }) => (zindex ? zindex : 2)};
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colorBackground_0)};

  ${({ isGradient }) =>
    isGradient !== false &&
    css`
      &::before {
        position: absolute;
        bottom: -1.25rem;
        left: 0;
        width: 100%;
        height: 1.25rem;
        content: '';
        background: linear-gradient(180deg, #fff.5%, rgb(255 255 255 / 0%) 100%);
      }
    `}

  .popup-content-full-width {
    background-color: ${({ theme }) => theme.colorBackground_0};
  }

  &:has(.popup-content-full-width) {
    &::before {
      right: -1.25rem;
      left: -1.25rem;
      width: auto;
    }
  }

  ${({ theme }) => theme.media.laptop} {
    position: static;
    top: 0;
    left: 0;

    &::before {
      display: none;
    }
  }
`;

/**
 * 약관용 iframe 요소 래퍼입니다.
 */
export const StyledIframeContainer = styled.div`
  height: 100%;

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.media.laptop} {
    position: relative;
    height: 548px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

/**
 * lottie 요소 래퍼입니다.
 */
export const StyledFeedbackLottie = styled.div`
  width: 4rem !important;
  height: 4rem !important;
`;

/**
 * 배너 위에 Indicator 컴포넌트를 위치시키기 위한 래퍼입니다.
 */
export const StyledSwiperIndicator = styled.div<{ top?: number; right?: number; zindex?: number }>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '0')};
  right: ${({ right }) => (right ? `${right}px` : '0')};
  z-index: ${({ zindex }) => (zindex ? zindex : 2)};
  opacity: 0.8;
`;

/**
 * 배너를 감싸는 래퍼입니다.
 */
export const StyledBannerWrapper = styled.div`
  &.mt-large {
    margin-top: 40px;
  }

  &:hover {
    .detail-view {
      color: ${({ theme }) => theme.colorText_body};
      background-color: ${({ theme }) => theme.colorSurface_neutral};
      transition: all 0.2s ease-out;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.75089 18.8485C8.28226 18.3799 8.28226 17.6201 8.75089 17.1514L13.9024 12L8.75089 6.84848C8.28226 6.37985 8.28226 5.62005 8.75089 5.15142C9.21951 4.68279 9.97931 4.68279 10.4479 5.15142L16.4479 11.1514C16.9166 11.6201 16.9166 12.3798 16.4479 12.8485L10.4479 18.8485C9.97931 19.3171 9.21952 19.3171 8.75089 18.8485Z' fill='%238C959D'/%3E%3C/svg%3E");
      }
    }
  }

  .swiper {
    padding: 0 1.25rem;
    margin: 0 -1.25rem;
  }

  ${({ theme }) => theme.media.laptop} {
    overflow: hidden;

    .swiper {
      padding: 0;
      margin: 0;
      border-radius: ${borderRadiusDesktop};
    }
  }
`;

/**
 * 마이그레이션, 콜드케이스 구성 화면 등에서 콘텐츠 중앙 정렬 래퍼입니다.
 */
export const StyledCenterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 ${Spacing.M} ${Spacing.Lx2};

  .fill-feedback {
    flex: 1;
  }

  ${({ theme }) => theme.media.laptop} {
    width: ${maxWidthDesktopCenter};
    padding: ${Spacing.Lx4} 0;
    margin: 0 auto;

    .fill-feedback {
      flex: none;
    }
  }
`;

export const StyledAutoHeightImage = styled.div`
  position: relative;
  width: 100%;

  .auto-image {
    position: relative !important;
    height: auto !important;
  }
`;
