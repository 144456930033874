import { Url } from './router.types';
import {
  isProductWebUrl,
  isFinanceWebUrl,
  isLocalPageUrl,
  isHomePageUrl
} from '@hanwhalife/shared-utils/validationUtil';

/**
 * Full Url 또는 url을 보고 프론트엔드 애플리케이션 코드를 리턴합니다.
 * @param webUrl (예: /app/something, https://direct.hanwhalife.com/app/something)
 * @returns
 */
export const getFrontendServiceFromUrl = (webUrl: string): 'hlp-direct' | 'hlp-product' | 'homepage' | 'etc' => {
  // 현재 금융서비스 프로젝트 케이스
  if (isFinanceWebUrl(webUrl) || isLocalPageUrl(webUrl)) {
    return 'hlp-direct';
  }

  if (isProductWebUrl(webUrl)) {
    return 'hlp-product';
  }

  if (isHomePageUrl(webUrl)) {
    return 'homepage';
  }

  return 'etc';
};

/**
 * 라우터의 pathname을 리턴합니다.
 * @param url
 * @returns
 */
export const getPathname = (url: Url) => {
  let pathname = '';
  if (typeof url === 'string') {
    pathname = url;
  }
  if (typeof url === 'object') {
    pathname = url.pathname as string;
  }

  return pathname;
};
