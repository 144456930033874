import qs from 'qs';

/*****************************
 * URL 처리 관련 유틸
 ******************************/

/**
 * 쿼리 스트링 존재여부 체크
 * @param webUrl
 * @returns
 */
export const isExistQueryString = (webUrl: string) => {
  try {
    return !!new URL(webUrl).search;
  } catch (e) {
    return false;
  }
};

/**
 * 최초에 인입됐던 쿼리 스트링을 붙인 URL을 리턴(상품웹을 위한 함수)
 * @param fullUrl
 */
export const getAddingInitQueryStringUrl = (fullUrl: string) => {
  // client side
  if (typeof window !== 'undefined') {
    const isExistQuery = isExistQueryString(fullUrl);
    const initQuery = (sessionStorage.getItem('init_query') ?? '').slice(1);
    const separator = isExistQuery ? '&' : initQuery ? '?' : '';

    return `${fullUrl}${separator}${initQuery}`;
  }

  return fullUrl;
};

/**
 * query 객체를 string으로 변환해줍니다.
 */
export const getWebQueryStringUrl = (query?: Record<string, string | string[] | undefined> | null) => {
  const queryParam = qs.stringify(query);

  return queryParam ? `${queryParam}` : ``;
};
