import React from 'react';
import styled from '@hanwhalife/shared-libs/emotion/styled';

import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { IconProblem } from '@/assets/svg';
import { error_primary } from '@/assets/metadata';
import { Feedback, Button } from '@hanwhalife/design-system';

const StyledError = styled.div`
  display: flex;
  justify-content: center;
  padding: 10rem 1.5rem 0;
  text-align: center;
`;

interface IErrorFallback {
  errorTitle?: React.ReactNode;
  errorDesc?: React.ReactNode;
  errorButton?: React.ReactNode;
}

export const ErrorFallback = ({ errorTitle, errorDesc, errorButton }: IErrorFallback) => {
  const router = useHlpRouter();

  return (
    <StyledError role="alert">
      <Feedback
        icon={() => <IconProblem width="64" height="64" />}
        title={errorTitle ? errorTitle : error_primary.errorTitle}
        bodyContents={errorDesc ? errorDesc : error_primary.errorDesc}
        ctaButton={
          errorButton ? (
            errorButton
          ) : (
            <Button size="small" onClick={() => router.backScreen()}>
              다시 시도하기
            </Button>
          )
        }
      />
    </StyledError>
  );
};
