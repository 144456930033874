/*****************************
 * 유효성 체크 관련 유틸
 ******************************/
import { naturalNumber } from './regexpUtils';

const BASE_HOSTNAME = 'direct.hanwhalife.com';
const BASE_ORIGIN = `https://${BASE_HOSTNAME}`;

/**
 * 다이렉트(금융) 웹 URL인지
 * /거나 /app으로 시작하는 pathname의 경우 금융서비스, 그외 비금융
 */
export const isFinanceWebUrl = (url: string) => {
  const { pathname } = new URL(url, BASE_ORIGIN);

  return pathname === '/' || pathname.startsWith('/app');
};

/**
 * 상품 웹 URL인지(상품청약, 보장분석 같은 금융서비스 외)
 * @param url
 * @returns
 */
export const isProductWebUrl = (url: string) => {
  const { pathname, hostname } = new URL(url, BASE_ORIGIN);

  // direct domain 이면서 금융 pathname이 아니면 상품으로 판단
  return hostname.includes(BASE_HOSTNAME) && isFinanceWebUrl(pathname) === false;
};

/**
 * 상품청약 웹 URL인지
 * @param url
 * @returns
 */
export const isProductSubscriptionWebUrl = (url: string) => {
  const { pathname } = new URL(url, BASE_ORIGIN);

  return pathname.startsWith('/products');
};

/**
 * 신규 상품 웹 URL인지
 * @param url
 * @returns
 */
export const isAnalysisWebUrl = (url: string) => {
  const { pathname } = new URL(url, BASE_ORIGIN);

  return pathname.startsWith('/analysis');
};

/**
 * 레거시 상품 웹(온슈어) URL인지
 * @param url
 * @returns
 */
export const isProductLegacyWebUrl = (url: string) => {
  return url.includes('onsure.co.kr');
};

/**
 * 홈페이지 모바일웹 URL인지
 */
export const isHomePageMoUrl = (url: string) => {
  return url.includes('m.hanwhalife.com');
};

/**
 * 홈페이지 PC웹 URL인지
 */
export const isHomePagePcUrl = (url: string) => {
  return url.includes('www.hanwhalife.com') || url.includes('qa.hanwhalife.com');
};

/**
 * 홈페이지 URL인지
 * @param url
 * @returns
 */
export const isHomePageUrl = (url: string) => {
  return isHomePageMoUrl(url) || isHomePagePcUrl(url);
};

/**
 * 문자열이 json인지
 */
export const isJson = (str: string) => {
  try {
    const parseString = JSON.parse(JSON.stringify(str));

    if (typeof parseString === 'string') {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log(e);

    return false;
  }
};

/**
 * URL에 파일 확장자가 있는지 검사
 * @param string
 * @returns
 */
export const hasExtension = (string: string) => /\.[0-9a-z]+$/i.test(string);

/**
 * 로그인 페이지 여부
 * @param pathname
 * @returns
 */
export const isLoginPageUrl = (pathname: string) => {
  return pathname.startsWith('/app/login') && hasExtension(pathname) === false;
};

/**
 * 로그아웃 페이지 여부
 * @param pathname
 * @returns
 */
export const isLogoutPageUrl = (pathname: string) => {
  return (
    (pathname.startsWith('/app/logout') || pathname.startsWith('/app/auto-logout')) && hasExtension(pathname) === false
  );
};

/**
 * 회원가입 페이지 여부
 * @param pathname
 * @returns
 */
export const isSignupPageUrl = (pathname: string) => {
  return pathname.startsWith('/app/signup') && hasExtension(pathname) === false;
};

/**
 * 로컬 페이지 여부
 * @param requestURI
 * @returns
 */
export const isLocalPageUrl = (url: string) => {
  return url.includes('//localhost') || url.includes('//127.0.0.1');
};

/**
 * 페이지 직접 호출 요청 여부
 * @param requestURI
 * @returns
 */
export const isDirectPageUrlRequest = (requestURI: string) => {
  return !requestURI.startsWith('/api') && !hasExtension(requestURI);
};

/**
 * 로그인 필요 페이지 여부
 * @param fullPathname: pathname + search
 * @returns
 */
export const isLoginRequiredPage = (fullPathname: string) => {
  const { pathname, searchParams } = new URL(fullPathname, 'https://direct.hanwhalife.com');

  const isLoginRequiredPathname =
    pathname.startsWith('/app/individual') ||
    pathname.startsWith('/app/aml') ||
    pathname.startsWith('/app/post-login') ||
    (pathname.startsWith('/app/event/list') && searchParams.get('filter') === 'applied');

  return isLoginRequiredPathname && hasExtension(pathname) === false;
};

/**
 * 로그인 시 접근 불가 페이지 pathname 체크
 * @param pathname
 */
export const isNoAccessWhenLogin = (pathname: string) => {
  return isLoginPageUrl(pathname) || isLogoutPageUrl(pathname) || isSignupPageUrl(pathname);
};

export const isNaturalNumber = (value: string) => {
  return naturalNumber.test(value) && !isNaN(Number(value));
};
