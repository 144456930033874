import styled from '@hanwhalife/shared-libs/emotion/styled';
import { maxWidthDesktopCenter, Spacing } from '@hanwhalife/styled/_shared/theme';

export const StyledError = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 1.25rem 1.25rem;

  /* //+ MoWeb에서 화면 전체 덮는 형상이 필요할 때 클래스 사용 */
  &.fixed-center {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: ${({ theme }) => theme.colorBackground_0};
  }

  .fill-feedback {
    flex: 1;
  }

  ${({ theme }) => theme.media.laptop} {
    width: ${maxWidthDesktopCenter};
    padding: 0;
    margin: 0 auto;

    &.fixed-center {
      position: relative;
      z-index: unset;
    }

    .fill-feedback {
      flex: none;
    }
  }
`;

export const StyledErrorCard = styled.div`
  padding: 1.5rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colorSurface_neutral_1};
  border-radius: ${({ theme }) => theme.radius.borderRadius_xLarge};

  ${({ theme }) => theme.media.laptop} {
    padding: 2rem;
    border-radius: 1.25rem;
  }
`;

export const StyledErrorFallback = styled.div`
  &.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
