import { useEffect } from 'react';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { useInitializeLoginInfo } from './useInitializeLoginInfo';

/**
 * App -> Web: componentRerender 함수 선언
 */
export const useInitializeAppWebComponentRerender = () => {
  const { initializeLoginInfo } = useInitializeLoginInfo({ initLoad: false });

  useEffect(() => {
    window.HLI_NATIVE_INTERFACE = {
      ...window.HLI_NATIVE_INTERFACE,
      componentRerender() {
        initializeLoginInfo();
      }
    };
  }, [initializeLoginInfo]);
};

/**
 * App -> Web: back pressed init
 */
export const useInitializeBackPressed = () => {
  const router = useHlpRouter();

  // router path 변경시 onBackPressed 기본 세팅
  useEffect(() => {
    window.HLI_NATIVE_INTERFACE = {
      ...window.HLI_NATIVE_INTERFACE,
      onBackPressed: () => {
        router.backScreen({
          byHardware: true
        });
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
};

/**
 * App -> Web: 호출용 함수 세팅
 * @returns
 */
export const useInitAppWebInterfaceFunctions = () => {
  // componentRerender 함수 선언
  useInitializeAppWebComponentRerender();

  // 네이티브 back pressed 함수 선언
  useInitializeBackPressed();
};

/**
 * back pressed custom function
 */
export const customizeBackPressedFunc = (customFn: () => void) => {
  window.HLI_NATIVE_INTERFACE = {
    ...window.HLI_NATIVE_INTERFACE,
    onBackPressed: customFn
  };
};
