import React, { useEffect, useState } from 'react';
import { ModalType } from '@/store/portal/portalStore.types';
import { usePortalStore } from '@/store/portal/portalStore';

import { Modal } from '@hanwhalife/design-system';
import { customizeBackPressedFunc } from '@hanwhalife/reactjs/hooks/init/useInitializeAppWebbFunctions';
import { useHlpRouter } from '@hanwhalife/reactjs/router';

export const Modals = () => {
  const { modalStore, closeCurrentModal, closeEntireModal } = usePortalStore();

  const router = useHlpRouter();

  // 현재 뜬 모달의 allowCloseModalOnBack: true면 뒤로가기시 해당 모달만 닫기
  useEffect(() => {
    /* customizeBackPressedFunc(() => {
      if (modalStore.length !== 0) {
        // allowCloseModalOnBack : 모달이 떠있을때 뒤로가기시 모달만 닫기 허용여부 (default : true)
        const { allowCloseModalOnBack = true } = modalStore[modalStore.length - 1];
        if (allowCloseModalOnBack) {
          closeCurrentModal();
        }
      } else {
        router.backScreen({
          byHardware: true
        });
      }
    }); */
  }, [modalStore]);

  // 모달이 제거되지 않았는데 페이지 이동 일어날때 제거
  useEffect(() => {
    closeEntireModal();
  }, [router.asPath]);

  return (
    <>
      {modalStore.map((data: ModalType, index) => (
        <Modal key={index} {...data.modalData} showModal></Modal>
      ))}
    </>
  );
};
