import React from 'react';
import Head from 'next/head';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { MODE, DIRECT_HOST, HLI_CDN_STATIC, metaMessage } from '@hanwhalife/constants';

interface IHtmlHead {
  /** HTML Title */
  title: string;

  /** Meta Description */
  description?: string;

  /** Meta Url */
  canonicalUrl?: string;

  /** Open Graph Image URL */
  ogImageUrl?: string;

  /** 추가 메타태그 */
  children?: React.ReactNode;

  /** 봇 접근 불가 여부 */
  isBotDisabled?: boolean;
}
/**
 * HTML Header Component
 * @param param0
 * @returns
 */
export function HtmlHead({ title, description, canonicalUrl, ogImageUrl, children, isBotDisabled = false }: IHtmlHead) {
  const router = useHlpRouter();
  const _url = `${DIRECT_HOST}${router.asPath}`;
  const _canonicalUrl = canonicalUrl ?? _url;
  const _description = description ?? metaMessage.description;
  const _ogImageUrl = ogImageUrl || `${HLI_CDN_STATIC}/hlp/direct/images/img_og_direct.png`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={_description} key="desc" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />

      {/* SEO - 로봇 페이지방문 여부 설정 */}
      <meta
        name="robots"
        content={isBotDisabled ? 'noindex,nofollow' : MODE === 'prod' ? 'index,follow' : 'noindex,nofollow'}
      />

      {/* SEO - 사이트 제작자명 */}
      <meta name="author" content={metaMessage.author} />

      {/* SEO - 사이트 키워드 */}
      {<meta name="keywords" content={metaMessage.keyword} />}

      {/* SEO - 검색엔진에 대표가 되는 URL주소 설정 */}
      <link rel="canonical" href={_canonicalUrl} key="canonical" />

      {/* og태그 설정 */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={_url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={_description} />
      <meta property="og:site_name" content="한화생명 다이렉트" />
      <meta property="og:locale" content="ko" />
      <meta property="og:image" content={_ogImageUrl} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="400" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={_url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={_description} />
      <meta name="twitter:image" content={_ogImageUrl} />
      {children}
    </Head>
  );
}
